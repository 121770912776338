import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faAt, faPhoneAlt, faMapMarkerAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import emailjs from 'emailjs-com';

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            show: false,
            success: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext

    handleSubmit(e) {
        e.preventDefault()

        if (this.state.name === "" || this.state.email === "" || this.state.message === "")
        {
            this.setState({ error: true })
        } else
        {
            this.setState({ error: false })
            const { name, email, subject, phone, message } = this.state
            let templateParams = {
                from_name: name,
                from_email: email,
                from_phone: phone,
                to_name: 'elachkoura@gmail.com',
                subject: subject,
                message_html: message
            }
            emailjs.send(
                'gmail',
                'template_T66IJnBd',
                templateParams,
                'user_SssmQbScCxKJc7aw4TdW6'
            )
            this.resetForm()
        }
    }

    resetForm() {
        this.setState({
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            success: true
        })
        this.removeIcon()
    }

    removeIcon() {
        setTimeout(() => {
            this.setState({
                success: false
            })
        }, 2000);
    }

    show() {
        this.setState({ show: true })
    }

    check(val) {
        if (this.state.error && val === "")
        {
            return false
        } else
        {
            return true
        }
    }

    render() {
        return (
            <section id={`${ this.props.id }`} className="contact" style={{ height: this.context.height }}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={10} className="form">
                        {this.form()}

                        <div class="map">
                            {this.map()}
                        </div>
                    </Col>
                    {/* <Col md={5} className="map">
                        {this.map()}
                    </Col> */}
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto")
        {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                    <div className="form-container">
                        <div className="line-text">
                            <h4>Contact Me</h4>
                            <div className="contact-list">
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faMobileAlt} className="contact_icon" />
                                        <span>+352 691 700 957</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faPhoneAlt} className="contact_icon" />
                                        <span>+212 633 401 861</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faAt} className="contact_icon" />
                                        <span>elachkoura@gmail.com</span>
                                    </li>
                                    {/* <li>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="contact_icon" />
                                        <span>20440 Casablanca, Morocco</span>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="line-text">
                            <h4>Get In Touch</h4>
                            <form className="contact-form" onSubmit={this.handleSubmit.bind(this)}>
                                <AnimationContainer delay={50} animation="fadeInUp fast">
                                    <div className="form-group">
                                        <input type="text" value={this.state.name} className={`name ${ this.check(this.state.name) ? "" : "error" }`} placeholder="Name" onChange={e => this.setState({ name: e.target.value })} />
                                    </div>
                                </AnimationContainer>
                                <AnimationContainer delay={100} animation="fadeInUp fast">
                                    <div className="form-group">
                                        <input type="text" value={this.state.email} className={`email ${ this.check(this.state.email) ? "" : "error" }`} placeholder="Email" onChange={e => this.setState({ email: e.target.value })} />
                                    </div>
                                </AnimationContainer>
                                <AnimationContainer delay={150} animation="fadeInUp fast">
                                    <div className="form-group">
                                        <input type="text" value={this.state.phone} className="phone" placeholder="Phone" onChange={e => this.setState({ phone: e.target.value })} />
                                    </div>
                                </AnimationContainer>
                                <AnimationContainer delay={200} animation="fadeInUp fast">
                                    <div className="form-group">
                                        <textarea value={this.state.message} className={`message ${ this.check(this.state.message) ? "" : "error" }`} placeholder="Message" onChange={e => this.setState({ message: e.target.value })}></textarea>
                                    </div>
                                </AnimationContainer>
                                <AnimationContainer delay={250} animation="fadeInUp fast">
                                    <div className="submit">
                                        <button type="submit" value="Send" className={`hover-button ${ this.state.error ? "error" : "" }`}>
                                            <span>Send Message</span>
                                        </button>
                                    </div>
                                </AnimationContainer>
                                <div className={`success-message ${ this.state.success ? "show" : "" }`}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="message_icon" />
                                </div>
                            </form>
                        </div>
                    </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto")
        {
            return (
                // <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                <AnimationContainer delay={1000} animation="fadeIn fast" >
                    {/* <iframe width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;q=AbdelDev&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" /> */}
                    <div className="copyright"><span>Copyright © {new Date().getFullYear()} EL ACHKOURA Abdelfattah</span></div>
                </AnimationContainer>
            )
        }
    }

}

export default Contact
