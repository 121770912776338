import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStackOverflow, faLinkedinIn, faFacebookF, faGithubAlt } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I'm a Full-Stack web &amp; mobile developer.</h3>
                            <div className="separator" />
                            <p>Hi! I am a web and mobile developer focused on crafting great web experiences. Designing and Coding have been my passion since the days I started working with computers but I found myself into web design/development since 2012. I enjoy creating beautifully designed, intuitive and functional websites and mobile applications.
                            <br /><br />
                            For over past 5 years, I have worked for some of the best digital agencies and wonderful clients to create some big works. And, I can make this happen for your business as well.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faLinkedinIn} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/elachkouraabdel/')} />
                                <FontAwesomeIcon icon={faFacebookF} className="social_icon" onClick={() => window.open('https://www.facebook.com/AbdelDev.Officiel/')}/>
                                <FontAwesomeIcon icon={faGithubAlt} className="social_icon" onClick={() => window.open('https://github.com/Elachkoura')}/>
                                <FontAwesomeIcon icon={faStackOverflow} className="social_icon" onClick={() => window.open('https://stackoverflow.com/users/7152911/abdelfattah-elachkoura')}/>
                                <span className="social_icon upwork-icon" onClick={() => window.open('https://www.upwork.com/freelancers/~0189b6b90ff237d347')}></span>
                                <img src="img/signature.png" className="my-signature" />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="HTML/CSS" value={95} delay={1100} />
                                <Progress name="Angular/Ionic" value={80} delay={1100} />
                                <Progress name="jQuery" value={80} delay={1100} />
                                <Progress name="PHP" value={60} delay={1100} />
                                <Progress name="ReactJs" value={40} delay={1100} />
                                <Progress name="Photoshp/Illustrator" value={85} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5,
                            "color": "#d82626"
                        },
                        "color": {
                            "value": "#d82626"
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero
