import React from 'react'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

class Whatsapp extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // const { duration } = this.props
  }

  render() {
    return (
      <div
        className={`whatsapp-container`} id="whatsapp"
      >
        <div className="content animate__animated animate__pulse animate__infinite	infinite" onClick={() => window.open(`https://wa.me/352691700957?text=Hello,%20i'm%20interested%20by%20your%20services%20!!`)}>
          <div className="icon">
            <FontAwesomeIcon icon={faWhatsapp} />
          </div>
          <div className="text">
            <p>Contact me</p>
          </div>
        </div>
        { }
      </div>
    )
  }
}

export default Whatsapp
